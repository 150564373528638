.content-wrapper{
  height: 92vh !important;
}
.small-box p {
  font-size: 20px !important;
  font-weight: 600;
}

[class*=sidebar-dark-] {
  background-color: #2d3840 !important;
}
/* 
[class*=sidebar-dark-] .sidebar a {
  color: #4c4c4d;
}
[class*=sidebar-dark-] .sidebar:hover a {
  color: #434344;
} */

/*CLIENT*/

.bg-light, .bg-light>a {
  color: #1f2d3d!important;
  padding: 1rem 3rem !important;
}

._container {
  width: 90%;
  margin: auto;
  background-color: transparent;
  margin-top: 2rem;
}