.mentionLegal {
  width: 70%;
  margin: 4rem auto;
  height: 50vh;
  padding-top: 4rem;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    color: rgb(22, 68, 82);
    text-align: center;
}

.content {
    padding: 2rem 4rem;
}



.txt p{
    font-size: 1.5rem;
    line-height: 2rem;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.infoCEO {
    padding-top: 4rem;
}

.infoCEO p {
    font-size: 1.5rem;
}

@media  (max-width: 767px){
    .mentionLegal {
      width: 90%;
      margin: 4rem auto;
      background-color: rgb(255, 255, 255);
      padding: 1rem;
    }
  }