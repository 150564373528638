/* input[type="date"]:disabled {
  color: #fff;
} */
p{
  font-size: 1rem;
}
.logo_ {
  width: 100px;
}

.title__ {
  font-size: 175% !important;
  font-weight: 600;
  color: #4c3e8b;
}

.btnGuideV {
  font-size: 100%;
  font-weight: 600;
  margin: 0;
  color: #4c3e8b;
}

.block_ h3 {
  font-weight: 600;
  font-size: 120%;
  text-align: center;
  /* color: #f9bc2e; */
}

.blocks_ h3{
  font-weight: 600;
  font-size: 110%;
  text-align: left;
  color: #f9bc2e;
}

.content-box {
  padding: 2rem;
  text-align: center;
}

.content-box p{
  font-size: 1rem;
}

.cardFooter{
  justify-content: space-between;
  align-items: center;
}
.userProfile{
  display: flex;
  align-items: center;
  justify-self: center;
}
.cardImg{
  width: 100%;
  height: 200px;
}

.cardImg img{
  width: 100%;
  height: 200px;
}
._fiara1{
  width: auto !important;
  height: auto;
  margin: auto !important;
  display: flex;
}

._fiara2{
  width: 100% !important;
  height: auto;
}
/* ._fiara1:hover {
  transform: scale(1.5);
} */

._fiaraDetail{
  width: 100% !important;
  height: auto;
}
._userName{
  margin-left: 5px;
}
.adress_{
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.ville_{
  font-size: 100%;
  font-weight: 500;
  /* color: #4c3e8b; */
  margin: 0;
  text-transform: capitalize;
}

.ville__{
  font-size: 100%;
  font-weight: 500;
  margin: 0;
}

.lName_{
  font-size: 100%;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  color: #a35d3c;
}

.cardFooter{
  display: flex;
}
.img-profile{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.contentHeader{
  align-items: center;
}

.contentHeader .listHeader{
  justify-content: space-between;
}
.page-item{
  cursor: pointer;
}

.headerTitle{
  font-size: 16px;
  font-weight: 600;
  color: rgb(54, 54, 54);
}
.subTitleHeader{
  font-size: 100%;
  font-weight: 700;
  color: rgb(54, 54, 54);
}

._homePage{
  margin-top: 6rem;
}

._btnShop{
  background-color: rgb(238, 238, 238);
}

._btnShop1{
  background-color: rgb(151, 7, 180);
  color: #FFF;
}
._price{
  font-size: 90%;
  font-weight: 600;
  margin: 0 !important;
  color: #3f3f3f;

}

._container {
  margin-top: 10rem;
}

.container_ {
  margin-top: 2rem;
  position: absolute;
  background-color: rgb(247, 247, 247);
  padding: 2rem 4rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.equipementList {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.btnComment{
  background-color: rgb(224, 224, 224);
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
}
.recent_{
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.recent_ h3 {
  font-weight: 600;
  font-size: 100%;
  color: #a35d3c;
}


.react-datepicker-ignore-onclickoutside {
  padding: 0.6rem !important;
  border: 2px solid gray !important;
  border-radius: 5px !important;
  width: 50% !important;
}

.contenu {
  padding: 2rem;
}

.bg-info {
  background-color: #e7eced!important;
  border-radius: 5px !important;
}

.bg-info, .bg-info>a {
  color: gray !important;
}

.small-box .icon>i {
  font-size: 250%;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #8080805c !important;
  transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transition: transform .3s linear,-webkit-transform .3s linear;
}

._contactBtn {
  background: #f9bc2e;
  color: #FFF;
}

.__contactBtn {
  border: 2px solid #f9bc2e;
  color: #f5a578;
  width: 100%;
  /* line-height: 7rem; */
  font-size: 1rem;
  transition: all .3s ease;
}

.__contactBtnCp {
  border: 2px solid #1f1f1f49;
  width: 100%;
  /* line-height: 7rem; */
  font-size: 1rem;
  transition: all .3s ease;
}


.boxBtn__ {
  line-height: 6rem;
}

.currencySelect_ {
  border: none !important;
  margin-left: 0.5rem;
  background-color: transparent !important;
  line-height: 1rem;
}

.__contactBtn :hover {
  border: 2px solid #f5a578;
  color: #f5a578;
  font-weight: 600;
  width: 100%;
  line-height: 7rem;
  font-size: 2rem;
}

._contactBtn:hover {
  background: #f5a578;
  color: #FFF;
}

.totalP_ {
  text-transform: inherit;
  font-size: 120%;
}
b{
  text-transform: initial;
  font-size: 120%;
  color: #a35d3c;
}

.loading {
  text-align: center !important;
  justify-content: centre !important;
  align-items: center !important;
  margin: auto !important;
}

.articleHead {
  /* display: block; */
  position: relative;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* transform: translateY(100%); */
}

.imgFond {
  z-index: 9;
  width: 100%;
  height: 250px;
  position: relative;
}


.textFond {
  font-size: 250%;
  position: absolute;
  z-index: 999;
  color: #fff;
  align-items: center;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
}

.listFooter_ {
  list-style: none;
  font-size: 110%;
}

.listFooter_ li {
  list-style: none;
  line-height: 2rem;
  font-size: 1.2rem;
  color: rgb(34, 33, 33);
  font-weight: 500;
}

.listFooter_ li a {
  list-style: none;
  font-size: 1.2rem;
  color: #4c3e8b;
}

.listFooter_ li a:hover {
  list-style: none;
  font-size: 1.2rem;
  color: #382491;
}

.listFooterr_ {
  list-style: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listFooterr_ li {
  list-style: none;
  line-height: 2.5rem;
  font-size: 110%;
  color: rgb(34, 33, 33);
  font-weight: 500;
}

.listFooterr_ li a {
  list-style: none;
  font-size: 110%;
  color: rgb(54, 54, 54);
}

.listFooterr_ li a:hover {
  list-style: none;
  font-size: 110%;
  color: rgb(34, 33, 33);
}

.listFooterr_ .logoFooter{
  width: 50px;
  display: inline;
}

.logoFooterr_{
  width: 50px;
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  transition: all .3s ease;
}
/* color: #a35d3c; */
.logoFooterr_:hover{
  width: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #fff;
  background-color: #535353;
  border-radius: 5px;
}

._logoFooter {
  color: #535353;
}

.logoFooterr_:hover ._logoFooter{
  color: #fff;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  width: 100% !important;
}

.containerOK {
  height: 70vh;
  width: auto;
  box-sizing: border-box;
  overflow: hidden;
}

.paymentCont {
  transform: translateY(50%);
}

.img_cl:hover {
  transform: scale(2);
}

.calendarStyle {
  width: 100% !important;
}

.rdrDefinedRangesWrapper {
  display: none !important;

}


.rdrDateRangePickerWrapper {
  display: flex !important;
  justify-content: space-around !important;
}

.rdrDayDisabled {
  background-color: rgb(216, 216, 216) !important;
}

.rdrMonth {
  width: 100% !important;
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
  text-transform: capitalize;
}

.nous-C {
  color: #4c3e8b;
  font-size: 1.1rem;
}

.rdrDateDisplayItem input {
  font-size: 1.2rem !important;
  font-weight: 600;
  text-transform: capitalize;

}

.rdrMonthAndYearPickers select {
  text-transform: capitalize !important;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
  width: 100% !important;
}

.rdrDay {
  font-size: 0.8rem !important;
}

.articleTitle {
  font-size: 2rem;
}

.payment
	{
    
    align-items: center;
		border:1px solid #f2f2f2;
		height:280px;
    border-radius:20px;
    background:#fff;
	}
   .payment_header
   {
	   background:#09b967;
	   padding:20px;
       border-radius:20px 20px 0px 0px;
	   
   }
   
   .check
   {
	   margin:0px auto;
	   width:50px;
	   height:50px;
	   border-radius:100%;
	   background:#fff;
	   text-align:center;
   }
   
   .check i
   {
	   vertical-align:middle;
	   line-height:50px;
	   font-size:30px;
   }

    .content 
    {
        text-align:center;
    }

    .content  h1
    {
        font-size:25px;
        padding-top:25px;
    }

    .content button
    {
        width:200px;
        height:35px;
        color:#fff;
        border-radius:30px;
        border: none;
        padding:5px 10px;
        background:#09b967;
        transition:all ease-in-out 0.3s;
    }

    .content button:hover
    {
        text-decoration:none;
        background:#000;
    }
/***FAQ**/   
.faq_container {
  width: 70%;
  margin: 4rem auto;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
}

.faq_title {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(22, 68, 82);
  text-align: center;
}

.faq_subtitlle {
  font-weight: 600;
  font-size: 1.2rem;
}

.btn-coockies {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}
/**END FAQ**/

@media  (max-width: 436px){
  .listHeader{
    justify-content: flex-start;
  }
  .equipementList {
    display: grid;
    grid-template-columns: auto;
  }
}

@media  (max-width: 767px){
  ._fiara1{
    width: auto !important;
    height: auto;
  }

  ._fiara2{
    width: 100% !important;
    height: auto;
  }
  .equipementList {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media  (max-width: 767px){
  .faq_container {
    width: 90%;
    margin: 4rem auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
  }
  .equipementList {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media  (max-width: 940){
  .equipementList {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

@media  (max-width: 1240px){
  .equipementList {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}