.aboutPage {
    width: 70%;
    margin: 4rem auto;
    height: auto;
    padding-top: 1rem;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    color: rgb(22, 68, 82);
    text-align: center;
}

.subtitle{
    font-size: 1.2rem;
    font-weight: 600;
    color: rgb(22, 68, 82);
    text-align: start;
}